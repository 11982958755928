import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {ReactComponent as Logo} from './../assets/logo.svg';
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";

const Header = () => {
  const { t, i18n } = useTranslation()

  return (
             <header className="App-header">
               <div className="logo">
               <NavLink to="/" exact><Logo /></NavLink>
               </div>
               <div className="navigation-menu">
                 <ul>
                   <li><NavLink to="/" exact>{t('home_menu.label')} </NavLink></li>
                   <li><NavLink to="/services" exact>{t('services_menu.label')}</NavLink></li>
                   <li><NavLink to="/work" exact>{t('work_menu.label')}</NavLink></li>
                   <li><NavLink to="/about" exact>{t('about_menu.label')}</NavLink></li>
                   <li><NavLink to="/contact" exact>{t('contact_menu.label')}</NavLink></li>
                 </ul>
               </div>
               <Popup
                 modal
                 closeOnDocumentClick={false}
                 trigger={open => <BurgerIcon open={open} />}
               >
                 {close => <Menu close={close} />}
               </Popup>
             </header>
         )
}

export default Header