import React, { Component } from 'react';
import ClientsList from "./ClientsList";
import config from './../config';

const API_ROOT = `${config.base}api`;
const CONTENT_TYPE = `clients`;
const LIST_URL = `${API_ROOT}/${CONTENT_TYPE}`;

class Work extends Component {

  constructor() {
    super();
    this.state = { data: null };
    this.loadClients = this.loadClients.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  componentWillMount() {
    this.loadClients();
  }

  loadClients() {
    // Fetch Clients.
    fetch(LIST_URL, {mode:'cors'})
      .then(function (response) {
        return response.json();
      })
      .then((data) => this.updateData(data))
      .catch(err => console.log('Fetching Clients Failed', err));
  }

  updateData(responseData) {
    this.setState({data: responseData});
  }

  render() {
    return (
      <div className="work">
        <ClientsList
          data={this.state.data}
        />
      </div>
    );
  }
}

export default Work;