import React from 'react';
import Modal from './Modal';
import { Portal } from 'react-portal';

export default class ClientsItem extends React.Component {
        constructor() {
            super();

            this.state = {
                isShowing: false
            }
        }

        toggleModal = () => {
            this.setState({
                isShowing: !this.state.isShowing
            });
          }
  
  render() {
    
    let logo = this.props.field_logo[0].url;
    let logo_white = this.props.field_logo_white[0].url;

    return (
      
      <div className="col">
        <img src={logo} className="open-modal-btn black" onClick={this.toggleModal} />
        <img src={logo_white} className="open-modal-btn white" onClick={this.toggleModal} />        
        <Portal>
        { this.state.isShowing ? <div onClick={this.toggleModal} className="back-drop"></div> : null }
        <Modal
            className="modal"
            show={this.state.isShowing}
            close={this.toggleModal}>
              <div className="modal-logo">
                <img src={logo} className="open-modal-btn black"/>
                <img src={logo_white} className="open-modal-btn white" />
              </div>
              <div className="modal-client"><span>Client: </span>{this.props.title[0].value}</div>
              <div className="modal-goal"><span>Goal: </span>{this.props.field_goal[0].value}</div>
              <div className="modal-tech"><span>Technologies: </span>{this.props.field_technologies[0].value}</div>
              {/* <div className="modal-desc"><span>Description: </span>{this.props.body[0].value}</div>   */}
                
        </Modal>
        </Portal>
        
      </div>
    );
  }

}
