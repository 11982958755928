import React from 'react';

function Privacy () {

    return (
        <div className="plain-text work">
            We have developed this Privacy Policy in order to demonstrate our firm commitment to the privacy of your data. We are committed to safeguarding your data whilst providing a personalised and valuable service. This online privacy policy is designed to tell you about how we collect and use information 
            so that you (“User” or “you”) can make an informed choice about using the website identified by the URL www.3xv.dev and/or any website which is automatically redirected to that website (together, the “Website”). The Website is operated by 3xV Software (“our”, “we” or “us”).<br />
If you have any requests concerning your personal information or any queries with regard to these practices please contact us by e-mail on hi@3xv.dev.<br />
In this privacy policy, we also refer to personal data as “data”, “information” or “personal information”. We only use information in order that our services can be offered as effectively as is possible.<br />
<br /><strong>Use of Personal Data</strong><br /><br />
Any Personal Data that you give us will be treated with the utmost care and security. It will not be used in ways to which you have not consented (either under this privacy policy or otherwise).<br />
The Personal Data we collect is used to provide you with the service that you have requested or to respond to an enquiry that you have made. We use certain third party service providers to assist us in providing these services to you, for example, legal, technical experts, surveyors, etc.<br />
<br /><strong>Security</strong><br /><br />
Whilst we use all reasonable measures we cannot guarantee the security of your data as we do not have control of the internet.<br />
<br /><strong>Transmission of Data</strong><br /><br />
Whilst we have taken all reasonable steps to ensure that the information you provide will be kept secure from unauthorised access we cannot guarantee that the information will be secure during transmission by you to this website.<br />
<br /><strong>Cookies</strong><br /><br />
Cookies are small pieces of information that are stored by your browser on your computer’s hard drive. Most web browsers automatically accept cookies, but you can usually change your browser to prevent that. We use cookies to enhance the service that we provide so that you do not have to fill in your details every time you visit the website. For more information please visit our Cookie Policy.<br />
<br /><strong>Hyperlinks</strong><br /><br />
This Website contains links to other websites. Their inclusion cannot be taken to imply any endorsement or validation by us of the content of the said websites. We are not responsible for the privacy practices, nor do we accept any liability in connection with the content of such websites, which have their own privacy policies tailored for the particular business practices and sectors in which they operate.<br />
<br /><strong>Terms &amp; Conditions</strong><br /><br />
This Privacy Policy should be read in conjunction with and is subject to our Terms (Click here to view the Terms &amp; Conditions).<br />
<br /><strong>Contact Us</strong><br /><br />
If you have any questions about this privacy policy or feel that your privacy has been compromised, please contact us at hi@3xv.dev.
        </div>
    )
}

export default Privacy;