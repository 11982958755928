import React from 'react';
import { useTranslation } from 'react-i18next'
import ReactHtml from 'raw-html-react';

const Hello = () => {
    const { t, i18n } = useTranslation()
  
    return (
        <div className="plain-text home">
        <ReactHtml html={t('home.label')} />
        </div>
    )
  }
  
  export default Hello