import React from 'react';
import config from './../config';
import axios from 'axios';
import Toast from 'light-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt, faExclamationTriangle, faEnvelope } from '@fortawesome/free-solid-svg-icons';


const API_ROOT = `${config.base}`;
const TOKEN_URL = `${API_ROOT}/session/token`;
const WEBFORM_URL = `${API_ROOT}webform_rest/submit?_format=json`;

function validate(name, email, message) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (name.length === 0) {
    errors.push("First name can't be empty");
  }

  if (email.split("").filter(x => x === "@").length !== 1) {
    errors.push("Email is not valid");
  }

  if (message.length < 10) {
    errors.push("Message should be at least 10 characters long");
  }

  return errors;
}

export default class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: []
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
    handleSubmit (event) {	
        event.preventDefault();
        const email = event.target.email.value;
        const message = event.target.message.value;
        const name = event.target.name.value;
        const last_name = event.target.last_name.value;
        const phone = event.target.phone.value;
        const errors = validate(name, email, message);
        if (errors.length > 0) {
          this.setState({ errors });
          return;
        }
        axios.get(TOKEN_URL).then(result => {
            console.log(result);
          if (200 === result.status) {
            const csrfToken = result.data;
            fetch(WEBFORM_URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
              },
              body: JSON.stringify({
                'webform_id':'contact',
                'entity_type' : null,
                'entity_id' : null,
                'in_draft' : false,
                'uri' : ['/webform/contact/api'],
                'name' : name, 
                'last_name' : last_name, 
                'email' : email,
                'phone' : phone,
                'message' : message,
              }),
            })
            .then(response => response.json())
            .then(
              (data) => {                
                if (data.error) {
                  console.log(data.error)
                } else {
                  Toast.info('Thank you for getting in touch! Will get back in touch with you soon!', 3000, () => {
                    document.getElementById("contact-form").reset();
                    this.setState({ errors });
                  });
                    
                }
                
              },
              (error) => {
                console.log('error', error);
              }
            );
          }
        });
    
      }
      
      render() {
        const { errors } = this.state;
        return (
          <div className="contact">
            <div id="responsive-form" className="clearfix">
            <div className="form-row">
                <div className="column-half">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span>48 Atanas Uzunov Str.<br />1505 Sofia, Bulgaria</span>
                </div>
                <div className="column-half">
                    <a className="contact-phone" href="tel:+359885313069"><FontAwesomeIcon icon={faPhoneAlt} />
                    <span>+359 885 313 069</span></a>
                    <a className="contact-phone" href="mailto:hi@3xv.dev"><FontAwesomeIcon icon={faEnvelope} />
                    <span>hi@3xv.dev</span></a>
                </div>
            </div>
            <form id="contact-form" className="form-inline my-2 my-lg-0" onSubmit={this.handleSubmit}>
            {errors.map(error => (
              <p className="error" key={error}><FontAwesomeIcon icon={faExclamationTriangle} /><span>Error: {error}</span></p>
            ))}
            <div className="form-row">
                <div className="column-half">
                    <input id="edit-name" className="form-control" type="text" placeholder="First name*" aria-label="Name" name="name" />
                </div>
                <div className="column-half">
                    <input id="edit-last_name" className="form-control" type="text" placeholder="Last name" aria-label="Last Name" name="last_name" />
                </div>
            </div>
            <div className="form-row">
                <div className="column-half">
                    <input id="edit-email" className="form-control" type="email" placeholder="E-mail*" aria-label="Email" name="email"  />
                </div>
                <div className="column-half">
                    <input id="edit-phone" className="form-control" type="text" placeholder="Phone" aria-label="Phone" name="phone"  />
                </div>
            </div>
            <div className="form-row">
                <div className="column-full">
                    <label>Message*</label>
                    <textarea  className="form-textarea form-control resize-vertical" id="edit-comment" name="message" rows="10" cols="60"></textarea>
                </div>
            </div>
            
            <div className="form-row">
                <div className="column-full">
                    <button className="webform-button--submit button button--primary js-form-submit form-submit btn-primary btn" type="submit">send</button>
                </div>
            </div>
            </form> 
            </div>
            </div>
        )
      }

  }