import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.className)
  }

  return (
    <div onClick={changeLanguage}>
      <span><a className="en" href="#">EN</a><a className="bg" href="#">BG</a><a href="#">DE</a></span>
    </div>
  )
}

export default LanguageSelector