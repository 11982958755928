import React, { useState, useEffect } from 'react';
import storage from 'local-storage-fallback';

export default function useTheme(
  defaultTheme = { mode: 'light'}
) {
  const [theme, _setTheme] = useState(getInitialTheme);

  function getInitialTheme() {
    const savedTheme = storage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : defaultTheme;
  }

  useEffect(() => { 
      storage.setItem('theme', JSON.stringify(theme)); 
        if (theme.mode === 'dark') {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    },[theme]);

  return {...theme,setTheme: ({ setTheme, ...theme }) => _setTheme(theme)};
}