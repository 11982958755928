import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import useTheme from './components/useTheme';
import style from 'styled-theming';
import ReactGA from 'react-ga';
import './i18n'
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './components/Home';
import Services from './components/Services';
import Work from './components/Work';
import About from './components/About';
import Privacy from './components/Privacy';
import Contact from './components/Contact';

import './App.css';


function App() {

  ReactGA.initialize('UA-157603991-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const theme = useTheme();
  const getBackground = style('mode', {
    light: '#fff',
    dark: '#000'
  });
  
  const getForeground = style('mode', {
    light: '#111',
    dark: '#fff'
  });
  
  const GlobalStyle = createGlobalStyle`
      body {
        background-color: ${getBackground};
        color: ${getForeground};
      }
  `;
  return (
    <Suspense fallback={null}>
    <ThemeProvider theme={theme}>
      <>
      <GlobalStyle />
      
      <div className="wrapper">
      
        <Header />
        <Route
              render={({ location }) => (
                <PageTransition
                  preset="flipTop"
                  transitionKey={location.pathname}
                >
            <main>
            
                <Switch location={location}>
                  <Route path="/" component={Home} exact />
                  <Route path="/services" component={Services} />
                  <Route path="/work" component={Work} />
                  <Route path="/about" component={About} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/privacy" component={Privacy} />
                  <Route component={Error} />
                </Switch>
                
            </main>
            </PageTransition>
      )}
    />
        <Footer />
      </div>
      
      </>
    </ThemeProvider>
    </Suspense>
  );
}

export default App;
