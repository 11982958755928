import React from 'react';
import { ThemeConsumer } from 'styled-components';

export default function ToggleMode() {
  return (
    <ThemeConsumer>
      {theme => (
        <a
          className="mode light rotate"
          onClick={e =>
            theme.setTheme(
              theme.mode === 'dark' ? { ...theme, mode: 'light' } : { ...theme, mode: 'dark' }
            )
          }
        ></a>
      )}
    </ThemeConsumer>
  );
}
