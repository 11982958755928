import React from 'react';
import ClientsItem from "./ClientsItem";

export default class ClientsList extends React.Component {

    render() {
        let { data } = this.props;
        return (
          <div className="flex-grid">
            {data !== null &&
            data !== undefined &&
            data.length > 0 ?
              data.map(item => <ClientsItem {...item} key={item.nid[0].value}/>)
              :
              <div>Loading clients.</div>
            }
          </div>
        );
      }
}