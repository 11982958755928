import React from "react";
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next'



const Menu = ({t, close}) => {
  return (
    <div className="menu">
    <ul>
        <li onClick={close}><NavLink to="/" exact>{t('home_menu.label')} </NavLink></li>
        <li onClick={close}><NavLink to="/services" exact>{t('services_menu.label')}</NavLink></li>
        <li onClick={close}><NavLink to="/work" exact>{t('work_menu.label')}</NavLink></li>
        <li onClick={close}><NavLink to="/about" exact>{t('about_menu.label')}</NavLink></li>
        <li onClick={close}><NavLink to="/contact" exact>{t('contact_menu.label')}</NavLink></li>
    </ul> 
  </div>
  )
}

export default withTranslation()(Menu)