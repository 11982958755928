import React, { useState, Component } from 'react';
import ToggleMode from './ToggleMode';
import LanguageSelector from './LanguageSelector'
import { NavLink } from 'react-router-dom';

function Footer () {

    const [isShown, setIsShown] = useState(false);
    const [isShownLang, setIsShownLang] = useState(false);
    
    return (
        <footer>
            <div className="col" onMouseLeave={() => setIsShownLang(false)}>
                <div onMouseEnter={() => setIsShownLang(true)} className={"language rotate " + (isShownLang ? 'actived' : '')}></div>
                <div className="languages">{isShownLang && (<LanguageSelector />)}</div>
            </div>
            <div className="col middle" onMouseLeave={() => setIsShown(false)}>
                <div className="copy">{isShown && (<p>&copy; 3XV Software</p>)}</div>
                <div onMouseEnter={() => setIsShown(true)} className={"info " + (isShown ? 'actived' : '')}></div>
                <div className="privacy">{isShown && (<NavLink to="/privacy" exact>Privacy Policy</NavLink>)}</div>
            </div>
            <div className="col"><ToggleMode /></div>
        </footer>
    )
}

export default Footer;