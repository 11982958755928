import React from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import { useTranslation } from 'react-i18next'
import ReactHtml from 'raw-html-react';
import { Accordion, AccordionItem } from 'react-sanfona';
import 'react-web-tabs/dist/react-web-tabs.css';


const Services = () => {
  const { t, i18n } = useTranslation()

    
    return (
      <div className="services">
        <div className="mobile-only">
        <Accordion openNextAccordionItem>

                <AccordionItem title={`WEB`} expanded>
                  <div>
                  <ReactHtml html={t('services_web.label')} />
                  </div>
                </AccordionItem>
                <AccordionItem title={`Mobile`}>
                  <div>
                  <ReactHtml html={t('services_mobile.label')} />
                  </div>
                </AccordionItem>
                <AccordionItem title={`Social`}>
                  <div>
                  <ReactHtml html={t('services_digital.label')} />
                  </div>
                </AccordionItem>

          </Accordion>
        </div>
        <Tabs defaultTab="vertical-tab-one" vertical>
        <TabList>
          <Tab tabFor="vertical-tab-one">Web</Tab>
          <Tab tabFor="vertical-tab-two">Mobile</Tab>
          <Tab tabFor="vertical-tab-three">Social</Tab>
        </TabList>
        <TabPanel tabId="vertical-tab-one">
          <ReactHtml html={t('services_web.label')} />
        </TabPanel>
        <TabPanel tabId="vertical-tab-two">
          <ReactHtml html={t('services_mobile.label')} />
        </TabPanel>
        <TabPanel tabId="vertical-tab-three">
          <ReactHtml html={t('services_digital.label')} />
        </TabPanel>
      </Tabs>
      </div>
    )
}

export default Services